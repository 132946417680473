



import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  computed: {
    ...mapState("assets", {
      url: "url"
    })
  },
  mounted() {
    const url = this.url[this.$route.params.id];
    if (url) {
      window.location.href = url;
    } else {
      window.location.href = this.$paths.home;
    }
  }
});
